import 'utils/wdyr'

import React from 'react'

import { ApolloProvider } from '@apollo/client'
import { AppProps, NextWebVitalsMetric } from 'next/app'

import ScrollRestorer from 'components/common/ScrollRestorer'
import { GlobalModalsProvider } from 'components/GlobalModalsProvider'
import MobileHeader from 'components/Layout/MobileHeader'

import ErrorContainer from 'containers/Error'

import { initializeApollo } from 'lib/apollo'
import { appWithTranslation } from 'lib/i18n'
import { logger } from 'lib/logger'

import { getSupportToken } from 'utils/authSupportCookies.utils'

import { AppPageProps, NextPageContextApp, PageWithLayout } from '../@types'
import TribeApp from '../app'

import 'emoji-mart/css/emoji-mart.css'
import '../public/carousel.scss'
import '../public/quill.scss'
import '../public/react-colorful.scss'

const NextApp = (
  props: AppProps & {
    Component: PageWithLayout
    pageProps: AppPageProps
  },
) => {
  const {
    Component,
    router,
    pageProps: {
      userAgent,
      apolloClient,
      authToken,
      apolloState = {},
      mustUseSupportToken,
      error,
      ...restPageProps
    } = {},
  } = props

  let supportToken
  if (typeof window !== 'undefined' && mustUseSupportToken) {
    const { networkId } = router.query || {}
    if (networkId)
      supportToken = getSupportToken(networkId && String(networkId))
  }
  const client = mustUseSupportToken
    ? initializeApollo(
        apolloState,
        {
          authToken,
        } as NextPageContextApp,
        supportToken,
      )
    : apolloClient ||
      initializeApollo(
        apolloState,
        {
          authToken,
        } as NextPageContextApp,
        supportToken,
      )

  let Content
  if (error) {
    logger.error(`_app error - ${error.message}`, { error })
    Content = () => <ErrorContainer error={error} />
  } else {
    const getLayout = Component.getLayout || (children => <>{children}</>)

    Content = () => {
      return (
        <>
          <MobileHeader />
          {getLayout(<Component {...(restPageProps as any)} />)}
          <ScrollRestorer />
        </>
      )
    }
  }

  return (
    <ApolloProvider client={client}>
      <GlobalModalsProvider>
        <TribeApp userAgent={userAgent}>
          <Content />
        </TribeApp>
      </GlobalModalsProvider>
    </ApolloProvider>
  )
}

export const reportWebVitals = (metric: NextWebVitalsMetric) => {
  logger.debug(metric)
}

export default appWithTranslation(NextApp)

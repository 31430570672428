import React from 'react'

import { useRouter } from 'next/router'
import { HTML5toTouch } from 'rdndmb-html5-to-touch'
import { DndProvider } from 'react-dnd-multi-backend'

import { ThemeTokens } from 'tribe-api'
import { TribeUIProvider } from 'tribe-components'

import RouteProgressBar from 'components/Layout/RouteProgressBar'

import { ThemeTokens as GlobalThemeTokens } from 'containers/AdminSettings/hooks/useThemeSettings'
import Navbar from 'containers/Network/components/Navbar'
import useGetNetwork from 'containers/Network/useGetNetwork'

import { initializeTribeWindow, setGlobalThemeSettings } from './lib/dom/window'
import { suppressAppLevelWarnings } from './lib/logger'

const TribeApp = ({
  children,
  userAgent,
}: {
  userAgent?: string | null
  children: any
}) => {
  const router = useRouter()
  initializeTribeWindow()

  // To turn off getNetwork query for the health page call
  const shouldSkipThisPage = router.asPath === '/_health'
  const { network } = useGetNetwork(undefined, { skip: shouldSkipThisPage })
  suppressAppLevelWarnings()

  if (shouldSkipThisPage) return children
  const themeSettings = network?.themes?.active?.tokens
  setGlobalThemeSettings(themeSettings as GlobalThemeTokens)

  return (
    <TribeUIProvider
      themeSettings={themeSettings as ThemeTokens}
      userAgent={userAgent}
    >
      <Navbar />
      <RouteProgressBar />
      <DndProvider options={HTML5toTouch}>{children}</DndProvider>
    </TribeUIProvider>
  )
}

export default TribeApp
